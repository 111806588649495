.tituloetapas1 {
	box-sizing: border-box;
	border: 1px solid #224459;
	margin: 1%;
	background-color: #79b4d9;

	padding-left: 15px;
	width: 98%;
}
.tituloetapas2 {
	box-sizing: border-box;
	border: 1px solid #224459;
	background-color: #567d8c;
	color: #fff;
	margin: 1%;
	padding-left: 15px;
	width: 98%;
}

.etapa-col1 {
	box-sizing: border-box;
	width: 500px;
	/* background-color: red; */
}
.etapa-col2 {
	width: 300px;
	/* background-color: blue; */
}
.etapa-col3 {
	width: 200px;
}

.bloque2 {
	box-sizing: border-box;
	width: 98%;
	margin: 1%;
}

.sirespuesta {
	box-sizing: border-box;
	width: 100%;
	background-color: #a7bfd3;
	font-size: 12px;
}
.norespuesta {
	box-sizing: border-box;
	width: 100%;

	background-color: pink;
	font-size: 12px;
}
.th1 {
	border-style: groove;
	border-color: #79b4d9;
	color: #224459;
}

.tarea-col0 {
	box-sizing: border-box;
	width: 3%;
	padding-left: 5px;
	border-style: groove;
	border-color: #79b4d9;
}
.tarea-col1 {
	box-sizing: border-box;
	width: 30%;
	padding-left: 5px;
	border-style: groove;
	border-color: #79b4d9;
}
.tarea-col2 {
	box-sizing: border-box;
	width: 6%;
	padding-left: 5px;
	border-style: groove;
	border-color: #79b4d9;
}
.tarea-col3 {
	box-sizing: border-box;
	width: 15%;
	padding-left: 5px;
	border-style: groove;
	border-color: #79b4d9;
}
.tarea-col4 {
	box-sizing: border-box;
	width: 20%;
	padding-left: 5px;
	border-style: groove;
	border-color: #79b4d9;
}

.btnVisible {
	box-sizing: border-box;
	margin-top: 5px;
	padding: 2px;
	background-color: rgb(85, 149, 201);
	color: #fff;
	height: 30px;
	font-size: 5px;
	height: 30px;
	border-bottom: none;
	border: 0px;
	border-radius: 10px;
}

.btnAsignar {
	box-sizing: border-box;
	margin-top: 5px;
	padding: 2px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: rgb(197, 222, 236);
	color: #224459;
	height: 30px;
	/* border: 10px; */
	/* height: 25px; */
	border-radius: 7px;
	border-color: #224459;
	border-bottom-style: solid;
	border-bottom-width: 2px;
}

.col-tabla-regprocesos1 {
	width: 40%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-regprocesos2 {
	width: 12%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}

.col-tabla-regtarea0 {
	width: 1%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-regtarea1 {
	width: 4%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 5px;
	padding-right: 5px;
}
.col-tabla-regtarea2 {
	width: 15%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 5px;
	padding-right: 5px;
}
.col-tabla-regtarea3 {
	width: 10%;
	height: 30px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 5px;
	padding-left: 5px;
}
.col-tabla-regtarea4 {
	width: 5%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 5px;
	padding-right: 5px;
}

.btn-ver-regprocesos {
	margin: 3px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #79b4d9;
	color: white;
	/* color: #224459; */
	border-radius: 5px;
}
.btnCloseRegprocesos {
	margin: 3px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: rgb(238, 80, 180);
	color: white;
	/* color: #224459; */
	border-radius: 5px;
}
.btnReinicio {
	margin: 3px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #e6d138;
	color: black;
	/* color: #224459; */
	border-radius: 5px;
}
.btnOpenRegprocesos {
	margin: 3px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: rgb(115, 236, 99);
	color: black;
	/* color: #224459; */
	border-radius: 5px;
}

.btn-close-reg-asign {
	background-color: #c41c1c;
	color: #fff;
	margin-left: 10%;
	width: 15%;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 40px;
	border-radius: 10px;
	font-size: 23px;
}
.btn-send-reg-asign {
	background-color: #6fd65f;
	color: #fff;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: 10%;
	min-width: 15%;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 40px;
	border-radius: 10px;
	font-size: 23px;
}
.btn-send-reg-asign2 {
	background-color: #6fd65f;
	color: #fff;
	margin-left: 10px;
	width: 35%;
	margin-top: 5px;
	margin-bottom: 5px;
	height: 30px;
	border-radius: 10px;
	font-size: 17px;
}
.btn-clean-reg-asign {
	background-color: white;
	color: black;
	margin-left: 10%;
	width: 15%;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 40px;
	border-radius: 10px;
	font-size: 23px;
}

.btnModif {
	background-color: #c41c1c;
	color: #fff;
	margin-left: 20px;
	width: 15%;
	margin-top: 10px;
	margin-bottom: 10px;
	height: 27px;
	border-radius: 10px;
	font-size: 17px;
}

.btnReturn {
	background-color: #e6d138;
	color: #000;
	margin-left: 10%;
	width: 30%;
	margin-top: 10px;
	margin-bottom: 10px;
	height: 35px;
	border-radius: 10px;
	font-size: 25px;
}
.btnViewCompliance {
	background-color: #6fd65f;
	color: #fff;
	margin-left: 10%;
	width: 15%;
	margin-top: 10px;
	margin-bottom: 10px;
	height: 35px;
	border-radius: 10px;
	font-size: 20px;
}

.contReturn {
	display: flex;
}
.contReturn01 {
	width: 70%;
}
.contReturn02 {
	width: 30%;
}

.contModif {
	display: flex;
}

.titleProcess {
	/* background-color: blue; */
	color: rgb(42, 45, 224);
	text-align: center;
	padding-top: 10px;
	font-size: 25px;
}

.responseColorEmpty {
	margin-top: 10px;
	margin-left: 20px;
	padding-left: 10px;
	width: 60px;
	background-color: pink;
	color: black;
	border-radius: 10px;
}
.responseColorFull {
	margin-top: 10px;
	margin-left: 20px;
	padding-left: 10px;
	width: 60px;
	background-color: greenyellow;
	/* color: greenyellow; */
	color: black;
	border-radius: 10px;
}

.textAlert {
	width: 50%;
	padding-top: 10px;
	padding-left: 10px;
	color: rgb(158, 17, 17);
	font-size: 12px;
}

@media only screen and (max-width: 800px) {
	.titleProcess {
		padding-top: 5px;
		font-size: 15px;
	}
	.col-tabla-regtarea1 {
		margin: 0px;
		height: auto;
		font-size: 9px;
		padding-left: 2px;
		padding-right: 2px;
	}
	.col-tabla-regtarea2 {
		margin: 0px;
		width: 15%;
		height: auto;
		font-size: 9px;
		padding-left: 2px;
		padding-right: 2px;
	}

	.col-tabla-regprocesos1 {
		margin: 0px;
		width: 40%;
		height: auto;
		font-size: 9px;
		padding-left: 2px;
		padding-right: 2px;
	}
	.col-tabla-regprocesos2 {
		margin: 0px;
		width: 12%;
		height: auto;
		font-size: 9px;
		padding-left: 2px;
		padding-right: 2px;
	}
	.responseColorEmpty {
		margin-top: 2px;
		margin-bottom: 2px;
		margin-left: 5px;
		padding-left: 5px;
		width: 40px;
		border-radius: 10px;
	}
	.responseColorFull {
		margin-top: 2px;
		margin-bottom: 2px;
		margin-left: 5px;
		padding-left: 5px;
		width: 40px;
		border-radius: 10px;
	}
	.btn-ver-regprocesos {
		margin: 2px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.th1 {
		font-size: 12px;
	}
	.bloque2 tr {
		font-size: 9px;
	}
	.tarea-col1 {
		width: auto;
	}
	.tarea-col2 {
		width: auto;
	}
	.tarea-col3 {
		width: auto;
	}
	.tarea-col4 {
		width: auto;
	}
	.btn-send-reg-asign {
		padding: 2px;
		height: auto;
		font-size: 15px;
		border-radius: 5px;
		width: auto;
	}
	.btn-close-reg-asign {
		padding: 2px;
		height: auto;
		font-size: 15px;
		border-radius: 5px;
		width: auto;
	}
	.btn-clean-reg-asign {
		padding: 2px;
		height: auto;
		font-size: 15px;
		border-radius: 5px;
		width: auto;
	}
}
