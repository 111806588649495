* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.containerTraining01 {
	display: flex;
	flex-direction: row;
	/* background-color: red; */
	padding: 0px;
}

.containerTraining02 {
	/* position: absolute; */
	width: 20%;
	background-color: rgb(37, 107, 238);
	height: 650px;
}
.containerTraining03 {
	width: 100%;
	overflow-y: scroll;
	height: 650px;
	/* background-color: green; */
}

.buttonTraining01 {
	background-color: rgb(6, 87, 236);
	width: 100%;
	height: 100px;
	text-align: left;
	padding-left: 20px;
	color: white;
}
.buttonTraining02 {
	background-color: rgb(65, 125, 238);
	width: 100%;
	min-height: 60px;
	text-align: left;
	padding-left: 20px;
	color: white;
}
.buttonTraining03 {
	background-color: white;

	color: rgb(14, 92, 238);
	width: 100%;
	min-height: 60px;
	text-align: left;
	padding-left: 20px;
}

.loadingFile {
	padding-left: 10px;
	font-size: 15px;
	color: rgb(21, 56, 213);
}
.loadedFile {
	padding-left: 10px;
	font-size: 20px;
	color: rgb(245, 246, 250);
}
.btnLoadFile {
	/* background-color: rgb(37, 107, 238); */
	color: white;
	padding-left: 10px;
	width: 90%;
}
.imgStart {
	width: 50%;
}

.alertEmpty {
	padding: 10px;
	font-size: 15px;
}
.containerIcon {
	text-align: center;
	height: 100vh;
	padding-top: 70px;
	background-color: rgb(220, 226, 248);
}

.btnReturnEvaluations {
	padding-left: 10px;
	padding-right: 10px;
	margin: 3px;
	margin-left: 20px;
	background-color: rgb(65, 125, 238);
	color: #fff;
	border-radius: 5px;
	font-size: 17px;
}

.containerQuestion {
	box-sizing: border-box;
	border: 1px solid #224459;
	margin: 1%;
	background-color: #cae3f3;
	/* background-color: whitesmoke; */

	padding-left: 15px;
	width: 98%;
	border-radius: 10px;
}

.questionCol01 {
	box-sizing: border-box;
	width: 900px;
	/* padding-bottom: 30px; */
	/* background-color: red; */
}
.questionCol02 {
	box-sizing: border-box;
	width: 80px;
	margin-left: 10px;
	padding-bottom: 10px;
	/* background-color: red; */
}

.buttonEditQuestion {
	box-sizing: border-box;
	margin: 5px;
	padding: 2px;
	font-size: 15px;
	height: 30px;
	border-radius: 7px;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	width: 150px;
	border-color: #224459;
	color: #224459;
	background-color: rgb(204, 240, 184);
}
.buttonEditAnswer {
	box-sizing: border-box;
	margin: 5px;
	padding: 2px;
	font-size: 15px;
	height: 25px;
	border-radius: 7px;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	width: 150px;
	background-color: rgb(229, 238, 224);
	color: #224459;
	border-color: #224459;
}
.buttonAddAnswer {
	box-sizing: border-box;
	margin-top: 0px;
	margin-left: 25px;
	padding: 2px;
	padding-left: 5px;
	padding-right: 5px;
	background-color: rgb(182, 240, 150);
	color: #224459;
	height: 30px;
	border-radius: 7px;
	border-color: #224459;
	border-bottom-style: solid;
	border-bottom-width: 2px;
}

.titulo2Evaluation {
	font-size: 20px;
	color: rgb(83, 10, 34);
	margin-left: 30px;
}

.btnVisibleAnswerOff {
	box-sizing: border-box;
	margin: 5px;
	padding: 2px;
	font-size: 15px;
	height: 30px;
	border-radius: 7px;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	width: 150px;
	background-color: rgb(176, 210, 238);
	border-color: #224459;
	color: #224459;
}
.btnVisibleAnswerOn {
	box-sizing: border-box;
	margin: 5px;
	padding: 2px;
	font-size: 15px;
	height: 30px;
	border-radius: 7px;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	width: 150px;
	background-color: rgb(124, 186, 236);
	border-color: #224459;
	color: #224459;
}

.containerChequedList {
	margin-left: 15px;
	margin-right: 15px;
	background-color: rgb(245, 249, 252);
	padding-left: 10px;
	padding-top: 5px;
	overflow-y: scroll;
	height: 250px;
}
.containerChequedListCourses {
	margin-left: 15px;
	margin-right: 15px;
	background-color: rgb(245, 249, 252);
	padding-left: 10px;
	padding-top: 5px;
	overflow-y: scroll;
	max-height: 100px;
}

.chequedlist {
	margin: 0px;
	padding: 0px;
	padding-left: 10px;
	box-sizing: border-box;
	height: auto;
}

.nameCheckbox {
	margin: 0px;
	padding: 0px;
	margin-left: 10px;
	padding-left: 10px;
	height: auto;
}

.containerEvaluation {
	margin: 0px;
	margin-top: 5px;
	margin-left: 0px;
	margin-right: 0px;
	background-color: rgb(198, 222, 240);
	padding-left: 10px;
	padding-top: 5px;
	overflow-y: scroll;
	max-height: 400px;
	border-radius: 10px;
}

.containerResponses {
	margin: 0px;
	margin-top: 5px;
	margin-left: 10px;
	margin-right: 15px;
	margin-bottom: 0px;
	background-color: white;
	padding-left: 15px;
	padding-top: 5px;
	padding-bottom: 7px;
	/* overflow-y: scroll;
	max-height: 400px; */
	border-radius: 7px;
}

.containerChecks2 {
	height: 300px;
	width: 100%;
}

.container1Check {
	float: left;
	height: 300px;
	width: 50%;
}

@media only screen and (max-width: 800px) {
	.containerIcon {
		text-align: center;
		height: 100vh;
		padding-top: 30px;
		background-color: rgb(220, 226, 248);
	}
	.containerIcon h2 {
		font-size: 15px;
	}
	.containerIcon img {
		width: 50%;
	}

	.containerTraining01 {
		display: flex;
		flex-direction: column;
		padding: 0px;
	}

	.containerTraining02 {
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		padding: 0px;
		align-content: flex-start;
	}

	.buttonTraining02 {
		width: auto;
		min-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}
	.buttonTraining03 {
		width: auto;
		min-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}
}
