* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.menuPermisionsInventory {
	margin: 10px;
	display: flex;
	flex-direction: row;
	background-color: rgb(140, 186, 222);
	margin-left: 10%;
	margin-right: 10%;
}

.btnShowPermisions {
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 700;
	margin: 3px;
	margin-left: 20px;
	background-color: hsl(63, 79%, 77%);
	/* color: white; */
	color: #000;
	border-radius: 5px;
	height: auto;
	/* margin-top: 25px; */
}
.btnErraseProduct {
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 500;
	margin: 3px;
	/* margin-left: 20px; */
	background-color: hsl(323, 82%, 79%);
	/* color: white; */
	color: #fff;
	border-radius: 5px;
	height: auto;
	/* margin-top: 25px; */
}

.containerInventory01 {
	display: flex;
	flex-direction: row;
	padding: 0px;
}

.containerInventory02 {
	/* position: absolute; */
	width: 200px;
	background-color: rgba(86, 125, 140);
	height: 650px;
}

.containerInventory03 {
	width: 100%;
	overflow-y: scroll;
	height: 650px;
}
.containerInventory04 {
	margin-top: 30px;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
	overflow-y: scroll;
	height: 650px;
}

.buttonInventory03 {
	background-color: white;
	color: rgba(86, 125, 140);
	width: 100%;
	min-height: 60px;
	text-align: left;
	padding-left: 20px;
	font-size: 30px;
}

.buttonInventory02 {
	/* background-color: rgba(86, 125, 140, 255); */
	background-color: rgba(86, 125, 140);
	width: 100%;
	min-height: 60px;
	text-align: left;
	padding-left: 20px;
	color: white;
	font-size: 20px;
}

.form-inventory {
	margin-left: 10px;
	margin-right: 10px;
	background-color: #abc6d2;
	border-radius: 10px;
}
.form-inventory2 {
	margin-left: 10px;
	margin-right: 10px;
	background-color: #8fafbd;
	border-radius: 10px;
}

.tabla-column-etiqueta {
	margin: 0px;
	padding: 0px;
	margin-left: 10px;
	color: rgba(86, 125, 140);
	padding-left: 10px;
	font-size: small;
	border-radius: 5px;
}
.tabla-column-data {
	margin: 0px;
	padding: 0px;
	margin-left: 10px;
	padding-left: 10px;
	height: auto;
	color: rgb(129, 138, 151);
}
.tabla-column-transform {
	margin: 0px;
	padding: 0px;
	margin-left: 10px;
	padding-left: 10px;
	height: auto;
	color: rgb(129, 138, 151);
}

.tabla-inventory {
	margin-left: 20px;
	margin-right: 20px;
	width: 95%;
}
.tabla-inventory-transform {
	border-radius: 10px;
	background: #abc6d2;
	margin-left: 20px;
	margin-right: 20px;
	width: 95%;
}
.tabla-inventory-light {
	margin-bottom: 10px;
	margin-right: 20px;
	width: auto;
}

.fila-tabla-inventory {
	background-color: #567d8c;
	color: #fff;
}
.fila-tabla-inventory-light {
	background-color: #79b4d9;
	color: #fff;
}

.titulo-tabla-inventory {
	padding-left: 5px;
	font-size: 20px;
	width: 500px;
	border-style: groove;
	border-color: #79b4d9;
}

.containerProductsReceipt {
	margin-left: 15px;
	margin-right: 15px;
	/* background-color: rgb(245, 249, 252); */
	padding-left: 10px;
	padding-top: 5px;
	overflow-y: scroll;
	max-height: 200px;
}
.containerProductsTransform {
	margin-left: 15px;
	margin-right: 15px;
	/* background-color: rgb(245, 249, 252); */
	padding-left: 10px;
	padding-top: 5px;
	overflow-y: scroll;
	max-height: 100px;
}

.col-tabla-inventory1 {
	/* width: 4%; */
	/* height: 40px; */
	width: 2%;
	font-size: 15px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 5px;
	padding-right: 5px;
}
.col-tabla-inventory2 {
	/* width: 4%; */
	/* height: 40px; */
	width: 10%;
	font-size: 15px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 5px;
	padding-right: 5px;
}

@media only screen and (max-width: 800px) {
	.containerInventory01 {
		display: flex;
		flex-direction: column;
		padding: 0px;
	}
	.containerInventory02 {
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		padding: 0px;
		align-content: flex-start;
	}

	.buttonInventory03 {
		width: auto;
		min-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}

	.buttonInventory02 {
		width: auto;
		min-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}
	.titulo-tabla-inventory {
		padding-left: 2px;
		font-size: 12px;
	}
	.col-tabla-inventory1 {
		font-size: 10px;
	}
	.btnShowPermisions {
		padding-left: 3px;
		padding-right: 3px;
		font-weight: 200;
		margin-left: 3px;

		width: auto;
	}
}
