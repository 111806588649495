* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.linkNavbar {
	color: rgb(42, 45, 224);

	font-size: 20px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.linkNavbarOn {
	color: rgb(236, 237, 243);
	font-size: 25px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 25px;
	padding-bottom: 25px;
}

.linkForgotPassword {
	color: rgb(230, 230, 240);
	font-size: 15px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.linkNavbar:hover {
	text-decoration: none;
	font-weight: bolder;
	/* color: rgb(3, 24, 94); */
}
/* .navbar {
	flex-direction: row;
	background-color: #93cdf1;
	position: sticky;
	top: 0;
} */

.navbar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: #93cdf1;
	width: 100%;
}

.mailNavbar {
	padding: 0;
	margin: 0;
	font-size: 12px;
	color: rgb(33, 12, 150);
}

/* sugerencias GPT */

.div02Navbar,
.divCloseNavbar {
	flex: 1;
	text-align: center;
}

/* .div01Navbar {
	width: 15px;
} */
.div01Navbar01 {
	/* background-color: red; */
	padding-top: 5px;
	padding-bottom: 5px;
	height: auto;
	width: auto;
}

.dropdownMenu {
	position: absolute;
	background-color: #93cdf1;
	background-color: #a9d5f0;
	width: 270px;
	border-radius: 5px;
	/* position: sticky; */
	/* top: 0; */
}
.dropdownMenu2 {
	position: absolute;
	background-color: #c7e3f5;
	width: 270px;
	border-radius: 5px;
	top: 128px;
	left: 270px;
}
.dropdownMenu2-4 {
	position: absolute;
	background-color: #c7e3f5;
	width: 270px;
	border-radius: 5px;
	top: 60px;
	left: 270px;
}
.div02Navbar {
	width: 15%;
}

.divCloseNavbar {
}
.closeButton {
	background-color: red;
	color: white;
	width: 70%;
	/* height: 100px; */
	margin: 20px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 10px;
	text-decoration: none;
}

.closeButton:hover {
	text-decoration: none;
	color: rgb(2, 8, 27);
}
.enlaces {
	width: 100%;
	margin: 20px;
}

.btn-editar {
	margin: 3px;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: 30px;
	background-color: #79b4d9;
	/* color: white; */
	color: white;
	border-radius: 10px;
}

.form-cuentas {
	margin-left: 20px;
	margin-right: 20px;
	background-color: #79b4d9;
	border-radius: 10px;
}

.tabla-cuentas {
	margin-left: 20px;
}

.fila-tabla-cuentas {
	background-color: rgb(201, 192, 201);
	font-size: 20px;
}

.titulo-tabla {
	padding-left: 10px;
	background-color: #224459;
	color: white;
	font-size: 30px;
	width: 500px;
	border-style: groove;
	border-color: #79b4d9;
}

.title2-account {
	font-size: 30px;
	padding-left: 10px;
	color: white;
}
.title3-account {
	font-size: 20px;
	color: white;
	padding-left: 10px;
}

.col-tabla-cuenta {
	width: 500 px;
	height: 40px;
	font-size: 25px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}

.input1 {
	margin: 15px;
	width: 30%;
	padding-left: 10px;
	height: 30px;
}
.input1_2 {
	background-color: #79b4d9;
	/* color: white; */
	/* margin: 15px; */
	align-items: center;
	margin: 0px;
	padding: 0px;

	width: 80%;
	/* padding-left: 10px; */
	/* height: 30px; */
}

.input1_3 {
	width: 80%;
	margin-left: 15px;
	background-color: #79b4d9;
}

.btn-form-cuentas {
	background-color: #224459;
	color: #fff;
	margin-left: 10%;
	width: 10%;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 40px;
	border-radius: 10px;
	font-size: 25px;
}

.btn-send-accounts {
	background-color: #6fd65f;
	padding-left: 10px;
	padding-right: 10px;
	color: #fff;
	margin-left: 10%;
	/* width: 15%; */
	margin-top: 20px;
	margin-bottom: 20px;
	height: 40px;
	border-radius: 10px;
	font-size: 23px;
}
.btn-close-accounts {
	padding-left: 10px;
	padding-right: 10px;
	background-color: #c41c1c;
	color: #fff;
	margin-left: 10%;
	/* width: 15%; */
	margin-top: 20px;
	margin-bottom: 20px;
	height: 40px;
	border-radius: 10px;
	font-size: 23px;
}
.btn-clean-accounts {
	padding-left: 10px;
	padding-right: 10px;
	background-color: white;
	color: black;
	margin-left: 10%;
	/* width: 15%; */
	margin-top: 20px;
	margin-bottom: 20px;
	height: 40px;
	border-radius: 10px;
	font-size: 23px;
}

.containerModalForgotPassword {
	/* background-image: url(../images/ciudadpanorama.jpg); */
	background-color: rgb(76, 124, 172);
	background-size: 100%;
	/* background-repeat: no-repeat; */
	display: flex;
	flex-direction: column;
	margin-right: 15px;
	padding: 15px;
	width: 100%;
	height: 350px;
}

.BtnCloseModal {
	width: 100%;
	/* align-items: center; */
	text-align: right;
}

@media only screen and (max-width: 800px) {
	.div02Navbar,
	.divCloseNavbar {
		width: 100%;
		text-align: center;
	}
	.div02Navbar {
		width: 50%;
	}

	.linkNavbar {
		font-size: 12px;
		padding: 0px;
	}
	.closeButton {
		color: red;
		background-color: #93cdf1;
		margin: 0px;
		padding-left: 0px;
		padding-right: 0px;
		padding-top: 0px;
		padding-bottom: 0px;
		border-radius: 0px;
		text-decoration: none;
		font-size: 12px;
	}
	.title2-account {
		margin: 0px;
		padding: 0px;
		font-size: 12px;
		padding-left: 5px;
	}
	.btn-clean-accounts {
		height: auto;
		border-radius: 5px;
		font-size: 15px;
	}
}

@media only screen and (max-width: 800px) {
	.input1_3 {
		font-size: 12px;
		height: auto;
	}
}

@media only screen and (max-width: 500px) {
	.div01Navbar {
		display: none;
	}
}
