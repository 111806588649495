* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.headerPO {
	margin: 10px;
	max-height: 160px;
	/* height: auto; */
	padding-left: 20px;
	border-radius: 10px;
	border: solid #79b4d9 2px;
	color: rgb(76, 124, 172);
	overflow-y: scroll;
}
