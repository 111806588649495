* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.menuPermisionsTime {
	margin: 10px;
	display: flex;
	flex-direction: row;
	background-color: rgb(140, 186, 222);
	margin-left: 10%;
	margin-right: 10%;
}

.containerTime01 {
	display: flex;
	flex-direction: row;
	padding: 0px;
}

.containerTime02 {
	width: 200px;
	background-color: #79b4d9;
	height: 650px;
}
.containerTime22 {
	width: 200px;
	background-color: rgb(129, 172, 238);
	height: 650px;
}

.containerTime03 {
	width: 100%;
	overflow-y: scroll;
	height: 650px;
}

.buttonTime03 {
	background-color: white;
	color: #79b4d9;
	width: 100%;
	min-height: 60px;
	text-align: left;
	padding-left: 20px;
	font-size: 30px;
}
.buttonTime23 {
	background-color: white;
	color: rgb(129, 172, 238);
	width: 100%;
	min-height: 60px;
	text-align: left;
	padding-left: 20px;
	font-size: 30px;
}
.buttonTime23R {
	background-color: white;
	color: rgb(197, 28, 212);
	width: 100%;
	min-height: 60px;
	text-align: left;
	padding-left: 20px;
	font-size: 30px;
}

.buttonTime02 {
	background-color: #79b4d9;
	width: 100%;
	min-height: 60px;
	text-align: left;
	padding-left: 20px;
	color: white;
	font-size: 20px;
}
.buttonTime22 {
	background-color: rgb(129, 172, 238);
	width: 100%;
	min-height: 60px;
	text-align: left;
	padding-left: 20px;
	color: white;
	font-size: 20px;
}
.buttonTime22R {
	background-color: rgb(197, 28, 212);
	width: 100%;
	min-height: 60px;
	text-align: left;
	padding-left: 20px;
	color: white;
	font-size: 20px;
}
.containerTime11 {
	margin: 0px 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: sticky;
	top: 0;
	background-color: rgb(242, 242, 247);
}

.containerTime12 {
	width: auto;
	padding-left: 20px;
	padding-right: 10px;
}

.containerTime13 {
	width: 100%;
	overflow-y: scroll;
	height: 650px;
}

.buttonTime13 {
	background-color: #0e9ea3;
	color: #e5edf1;
	width: auto;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 20px;
	border-radius: 5px;
}

.buttonTime12 {
	background-color: #c5fafc;
	color: rgb(99, 96, 96);
	width: auto;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 15px;
	border-radius: 5px;
}

.btnClearTimeAll {
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 500;
	font-size: 20px;
	margin: 7px;
	margin-left: 20px;
	background-color: hsl(323, 86%, 66%);
	color: #fff;
	border-radius: 5px;
	height: auto;
}
.btnClearTimeParticular {
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 500;
	margin: 3px;
	margin-left: 5px;
	background-color: hsl(323, 86%, 66%);
	color: #fff;
	border-radius: 5px;
	height: auto;
}

@media only screen and (max-width: 800px) {
	.containerTime01 {
		display: flex;
		flex-direction: column;
		padding: 0px;
	}

	.containerTime02 {
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		padding: 0px;
		align-content: flex-start;
	}

	.containerTime22 {
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		padding: 0px;
		align-content: flex-start;
	}

	.buttonTime23 {
		margin: 0px;
		min-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}

	.buttonTime23R {
		min-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}

	.buttonTime22 {
		min-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}
	.buttonTime22R {
		min-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}
	.buttonTime03 {
		min-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}
	.buttonTime02 {
		min-height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}

	.containerTime11 {
		margin: 0px 0px;

		position: sticky;
		top: 0;

		height: auto;
		display: flex;
		flex-wrap: wrap;
		padding: 0px;
		align-content: flex-start;
	}

	.containerTime12 {
		width: auto;
		padding-left: 0px;
		padding-right: 0px;
	}
	.buttonTime13 {
		font-size: 12px;
	}

	.buttonTime12 {
		font-size: 12px;
	}

	.btnClearTimeAll {
		font-weight: 300;
		font-size: 12px;
	}
}
