.modalito {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.75);
	display: none;
	justify-content: center;
	align-items: center;
}

.modalito.is-open {
	display: flex;
}

.modal-container {
	position: relative;
	padding: 10px;
	width: 90%;
	min-height: 200px;
	max-height: 800px;
	background-color: #fff;
	border-radius: 10px;
	overflow-y: scroll;
}

.modal-close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	border-radius: 5px;
	background-color: #224459;
	color: #fff;
	width: 40px;
}

@media only screen and (max-width: 800px) {
	modalito {
		width: 98%;
	}

	.modalito.is-open {
		display: flex;
	}

	.modal-container {
		margin: 1%;
		padding: 1%;
		width: 80%;
		min-height: 200px;
		max-height: 300px;
	}
}
