* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.formRH {
	margin-left: 10px;
	margin-right: 10px;
	padding-top: 5px;
	background-color: #79b4d9;
	border-radius: 10px;
}

.containerFormRH {
	overflow-y: scroll;
	margin: 10px;
	padding: 10px;
	max-height: 300px;
	background-color: rgb(199, 220, 245);
	border-radius: 10px;
}

.humanInput0 {
	margin: 0px 0px 20px 10px;
	width: 29%;
	padding-left: 10px;
	height: auto;
	border-radius: 5px;
	border-style: none;
}
.humanTexArea0 {
	margin: 0px 0px 20px 10px;
	max-width: 50%;
	padding-left: 10px;
	height: auto;
	border-radius: 5px;
	border-style: none;
}
.humanSelect0 {
	display: inline-block;
	margin: 0px 0px 20px 10px;
	width: 29%;
	padding-bottom: 0px;
	height: auto;
	border-radius: 5px;
	border-style: none;
	/* white-space: wrap; */
	/* overflow: hidden;
	text-overflow: ellipsis; */
}
.humanLabel0 {
	/* background-color: coral; */
	text-align: right;
	margin: 0px 0px 0px 10px;
	width: 15%;
	font-size: 11px;
	padding-left: 10px;
	height: auto;
	border-radius: 5px;
}

.ContainerHojaVida {
	display: flex; /* Utilizamos flexbox para controlar el diseño */
}

.containerCvLeft {
	flex: 1; /* Hace que ambos divs internos ocupen el mismo espacio */
	/* Puedes ajustar otras propiedades según sea necesario */
	background-color: rgb(221, 235, 252);
	margin: 10px;
	padding: 10px;
	border-radius: 10px;
}

.containerCvRigth {
	flex: 1; /* Hace que ambos divs internos ocupen el mismo espacio */
	/* Puedes ajustar otras propiedades según sea necesario */
	background-color: rgb(221, 235, 252);
	margin: 10px;
	padding: 10px;
	border-radius: 10px;
}

.input-container {
	/* background-color: red; */

	position: relative;
	overflow: hidden;
	display: flex; /* Utilizamos flexbox para centrar verticalmente */
	align-items: center; /* Centramos verticalmente el contenido */
}

.input-container input[type="file"] {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 100px;
	opacity: 0;
	cursor: pointer;
	width: 100%;
	height: 100%;
}

.input-container label {
	display: block;
	background-color: #f34fb4;
	/* background-color: #007bff; */
	color: #fff;
	padding: 1px 20px;
	border-radius: 5px;
	cursor: pointer;
}

/* .input-container input[type="file"] {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 100px;
	opacity: 0;
	cursor: pointer;
	width: 100%;
	height: 100%;
}

.input-container label {
	display: block;
	background-color: #4b9aee;
	color: #fff;
	padding: 1px 10px;
	border-radius: 5px;
	cursor: pointer;
} */
