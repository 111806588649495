* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
}
/* .container2 {
	background-image: url(../generalComponents/images/ciudadpanorama.jpg);
	background-size: 100%;
	
	display: flex;
	flex-direction: column;
	margin-right: 15px;
	padding: 15px;
	width: 100%;
	height: 100vh;
} */

.container2 {
	background-image: url(../generalComponents/images/ciudadpanorama.jpg);
	background-size: cover;
	background-position: center; /* Añadir esto para centrar la imagen */

	display: flex;
	flex-direction: column;
	margin-right: 15px;
	padding: 15px;
	width: 100%;
	height: 100vh; /* Utilizar 100vh para ocupar la altura total de la pantalla */
}

.login {
	margin-top: 30px;
	margin-bottom: 250px;
	padding-left: 10%;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	width: 50%;
	height: 100%;
}

.fondo form {
	width: 100%;
	background-color: rgba(31, 141, 166, 0.5);
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
}

.text-sinpermiso {
	margin-top: 50px;
	margin-left: 20px;
	padding: 10px;
	background-color: red;
	font-size: 20px;
	color: white;
	border-radius: 20px;
}

.titleLogin {
	color: rgb(242, 244, 247);
	font-size: 40px;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra negra con opacidad */
	text-align: center;
	background-color: rgb(129, 172, 238);
	border-radius: 5px;
	padding: 10px;
}

@media only screen and (max-width: 800px) {
	* {
		border-width: 1px;
	}

	.titleLogin {
		font-size: 20px;
		border-radius: 5px;
		padding: 10px;
	}

	.login {
		margin-top: 30px;
		margin-bottom: 250px;
		padding-left: 10%;
		display: flex;
		flex-direction: column;
		/* align-items: center; */
		width: 90%;
		height: 100%;
	}
}
