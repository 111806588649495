.titulo2 {
	font-size: 20px;
	color: rgb(228, 38, 101);
	margin: 30px;
}

.conten0 {
	margin: 10px;
}

.emp {
	margin-left: 15px;
}

/* .input0 {
	margin: 10px;
	width: 95%;
	padding-left: 10px;
	height: auto;
	border-radius: 5px;
} */

.enlaces {
	width: 100%;
	margin: 20px;
}

.btnEditDocuments {
	padding-left: 10px;
	padding-right: 10px;
	margin: 3px;
	margin-left: 20px;
	background-color: #79b4d9;
	/* color: white; */
	color: #fff;
	border-radius: 5px;
}

.btnDeleteDocuments {
	padding-left: 10px;
	padding-right: 10px;
	margin: 3px;
	margin-left: 20px;
	background-color: #c41c1c;
	/* color: white; */
	color: #fff;
	border-radius: 5px;
}

.btnViewDocuments {
	padding-left: 10px;
	padding-right: 10px;
	margin: 3px;
	margin-left: 20px;
	background-color: #6fd65f;
	/* color: white; */
	color: #fff;
	border-radius: 5px;
}

.form-procesos {
	margin-left: 10px;
	margin-right: 10px;
	background-color: #79b4d9;
	border-radius: 10px;
}

.fila-tabla-procesos {
	background-color: #567d8c;
	font-size: 17px;
	color: #fff;
}

.titleTableDocuments01 {
	padding-left: 15px;
	font-size: 20px;
	width: 3%;
	border-style: groove;
	border-color: #79b4d9;
}
.titleTableDocuments02 {
	padding-left: 15px;
	font-size: 20px;
	width: 10%;
	border-style: groove;
	border-color: #79b4d9;
}
.titleTableDocuments03 {
	padding-left: 15px;
	font-size: 20px;
	width: 1%;
	border-style: groove;
	border-color: #79b4d9;
}
.titleTableDocuments04 {
	padding-left: 15px;
	font-size: 20px;
	width: 15%;
	border-style: groove;
	border-color: #79b4d9;
}
/* .titulo-tabla-procesos1 {
	padding-left: 15px;
	font-size: 25px;
	width: 15%;
	border-style: groove;
	border-color: #79b4d9;
}
.titulo-tabla-procesos2 {
	padding-left: 15px;
	font-size: 25px;
	width: 30%;
	border-style: groove;
	border-color: #79b4d9;
}
.titulo-tabla-procesos3 {
	padding-left: 15px;
	font-size: 25px;
	width: 25%;
	border-style: groove;
	border-color: #79b4d9;
} */

.colTableDocuments0 {
	/* width: 40%; */
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.colTableDocuments1 {
	/* width: 40%; */
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.colTableDocuments2 {
	/* width: 40%; */
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-etapas1 {
	width: 5%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-etapas2 {
	width: 30%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-etapas3 {
	width: 18%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-etapas4 {
	width: 7%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}

.titulo-tabla-etapas1 {
	width: 400px;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}

.btn-form-procesos {
	background-color: #224459;
	color: #fff;
	margin-left: 10%;
	width: 15%;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 40px;
	border-radius: 10px;
	font-size: 23px;
}
.btn-create-document {
	background-color: #6fd65f;
	padding-left: 20px;
	padding-right: 20px;
	color: #fff;
	margin-left: 50px;
	width: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 40px;
	border-radius: 10px;
	font-size: 20px;
}
.btn-create-document-process {
	background-color: #acdaa5;
	padding-left: 10px;
	padding-right: 10px;
	color: rgb(17, 2, 2);
	margin-left: 30px;
	width: auto;
	margin-top: 0px;
	margin-bottom: 0px;
	height: 24px;
	border-radius: 5px;
	font-size: 15px;
}
.btn-return {
	background-color: #93c5f4;
	padding-left: 10px;
	padding-right: 10px;
	color: white;
	margin-left: 10px;
	width: auto;
	margin-top: 0px;
	margin-bottom: 0px;

	border-radius: 5px;
	font-size: 20px;
}

.btnEditEmployee {
	background-color: #4ea541;
	color: white;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: 50px;
	width: auto;
	margin-top: 0px;
	margin-bottom: 0px;
	margin: 0px;
	border-radius: 5px;
	font-size: 17px;
}

.titleSearchColumn01 {
	padding-left: 5px;
	padding-right: 5px;
	font-size: 17px;
	min-width: 70px;
	max-width: 10%;
	border-style: groove;
	border-color: #79b4d9;
}

.searchColumn {
	background-color: rgb(236, 242, 247);
	width: 100%;
	border-radius: 5px;
	margin-bottom: 2px;
}

.col-tabla-tarea1 {
	width: 4%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-tarea2 {
	width: 15%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-tarea3 {
	width: 10%;
	height: 30px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-tarea4 {
	width: 5%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}

.filter-procesos {
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 10px;
	width: 95%;
	background-color: #dcebf5;
	border-radius: 10px;
}

.view-documents {
	width: 100%;
	height: 100%;
}
.view-documents-container {
	width: 100%;
	height: 100vh;
	overflow-y: auto;
}

.document-search {
	background-color: #dcebf5;
	width: 70%;
	margin: 5px;
	margin-left: 20px;
	padding: 10px;
	border-radius: 15px;
	display: flex;
	flex-direction: row;
}

.title-search {
	margin-left: 10px;
	font-size: 15px;
	margin-right: 20px;
}

.search-box {
	border-radius: 10px;
	width: 80%;
	margin-left: 5px;
	padding-left: 10px;
}

.titleDocuments {
	/* background-color: blue; */
	text-align: center;
	padding: 10px;
}

.labelSelect {
	margin-left: 20px;
}

@media only screen and (max-width: 800px) {
	.document-search {
		background-color: #dcebf5;
		width: 97%;
		margin: 2px;
		margin-left: 5px;
		margin-bottom: 5px;
		padding: 5px;
		border-radius: 10px;
		display: flex;
		flex-direction: row;
	}

	.erraseResponsive {
		display: none;
	}

	.search-box {
		border-radius: 7px;
		width: 90%;
		padding-left: 5px;
		font-size: 12px;
		border-width: 1px;
	}

	.title-search {
		margin-left: 5px;
		font-size: 10px;
		margin-right: 10px;
	}

	.titleSearchColumn01 {
		padding-left: 2px;
		padding-right: 2px;
		font-size: 12px;
	}
	.btn-create-document {
		padding-left: 10px;
		padding-right: 10px;
		margin-left: 30px;
		width: auto;
		margin-top: 10px;
		margin-bottom: 10px;
		height: 20px;
		border-radius: 5px;
		font-size: 12px;
	}
	.titulo2 {
		font-size: 12px;
		color: rgb(228, 38, 101);
		margin: 10px;
	}
	.titleTableDocuments01 {
		padding-left: 5px;
		font-size: 12px;
	}
	.titleTableDocuments02 {
		padding-left: 5px;
		font-size: 12px;
	}
	.titleTableDocuments03 {
		padding-left: 5px;
		font-size: 12px;
	}
	.titleTableDocuments04 {
		padding-left: 5px;
		font-size: 12px;
	}

	.colTableDocuments1 {
		height: auto;
		font-size: 10px;
		padding-left: 5px;
	}
}
