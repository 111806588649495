.conten0 {
	margin: 10px;
}

.emp {
	margin-left: 15px;
}

.labelForm1 {
	font-size: 17px;
	padding-left: 10px;
	color: rgb(16, 6, 70);
}

.alertaFormulario {
	font-size: 12px;
	padding-left: 10px;
	color: brown;
}

.input0 {
	margin: 10px;
	width: 95%;
	padding-left: 10px;
	height: auto;
	border-radius: 5px;
}
.input2 {
	background-color: rgb(237, 237, 233);
	margin-left: 2%;
	margin-right: 2%;
	width: 95%;
	padding-left: 10px;
	min-height: 3rem;
	/* height: auto; */
	/* max-height: min-content; */
	/* height: 50px; */
	border-radius: 5px;
}
.input3 {
	background-color: white;
	margin-left: 10px;
	margin-right: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 170px;
	padding-left: 10px;
	height: 30px;
	border-radius: 5px;
}

.enlaces {
	width: 100%;
	margin: 20px;
}

.btn-editar-procesos {
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 700;
	margin: 3px;
	margin-left: 20px;
	background-color: hsl(63, 79%, 77%);
	/* color: white; */
	color: #000;
	border-radius: 5px;
}
.btn-release-process {
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 700;
	margin: 3px;
	margin-left: 20px;
	background-color: #e46cde;
	/* color: white; */
	color: #fff;
	border-radius: 5px;
}
.btn-unrelease-process {
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 700;
	margin: 3px;
	margin-left: 20px;
	background-color: #630a66;
	height: 30px;
	/* color: white; */
	color: #fff;
	border-radius: 5px;
}
.btn-delete-process {
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 700;
	margin: 3px;
	margin-left: 20px;
	background-color: red;
	color: white;
	/* color: #fff; */
	border-radius: 5px;
}
.btn-show-stage {
	padding-left: 10px;
	padding-right: 10px;
	/* font-weight: 600; */
	margin: 3px;
	margin-left: 20px;
	background-color: #6fd65f;
	/* color: white; */
	color: #fff;
	border-radius: 5px;
	font-size: 17px;
}
.btn-editar-etapa {
	font-weight: 700;
	padding-left: 10px;
	padding-right: 10px;
	margin: 3px;
	margin-left: 5px;
	background-color: #79b4d9;
	/* color: white; */
	color: white;
	border-radius: 5px;
}
.btn-cambiar-etapa {
	margin: 2px;
	margin-left: 5px;
	background-color: #6fd65f;
	color: black;
	/* color: #224459; */
	border-radius: 20px;
	font-size: 12px;
	font-weight: 700;
	/* font-weight: bold; */
	width: auto;
	height: 25px;
}

.btnActive {
	/* margin: 2px; */
	margin-left: 5px;
	/* padding: 2px; */
	background-color: #6fd65f;
	color: black;
	/* color: #224459; */
	border-radius: 35px;
	font-size: 20px;
	font-weight: 700;
	/* font-weight: bold; */
	width: auto;
	/* height: 25px; */
}
.btnInActive {
	/* margin: 2px; */
	margin-left: 5px;
	/* padding: 2px; */
	background-color: red;
	color: white;
	/* color: #224459; */
	border-radius: 35px;
	font-size: 20px;
	font-weight: 700;
	/* font-weight: bold; */
	width: auto;
	/* height: 25px; */
}
.sino {
	width: 20px;
	margin-right: 3px;
	/* padding: 3px; */
	/* text-align: left; */
}

.form-procesos {
	margin-left: 10px;
	margin-right: 10px;
	background-color: #79b4d9;
	border-radius: 10px;
}

.tabla-procesos {
	margin-left: 20px;
	margin-right: 20px;
	width: auto;
}
.tabla-procesos-light {
	margin-bottom: 10px;
	margin-right: 20px;
	width: auto;
}

.fila-tabla-procesos {
	background-color: #567d8c;
	color: #fff;
}
.fila-tabla-procesos-light {
	background-color: #79b4d9;
	color: #fff;
}

.titulo-tabla-procesos {
	padding-left: 5px;
	font-size: 20px;
	width: 500px;
	border-style: groove;
	border-color: #79b4d9;
}
.titulo-tabla-procesos1 {
	padding-left: 15px;
	font-size: 25px;
	width: 10%;
	border-style: groove;
	border-color: #79b4d9;
}
.titulo-tabla-procesos2 {
	padding-left: 15px;
	font-size: 25px;
	width: 30%;
	border-style: groove;
	border-color: #79b4d9;
}
.titulo-tabla-procesos3 {
	padding-left: 15px;
	font-size: 25px;
	width: 25%;
	border-style: groove;
	border-color: #79b4d9;
}

.col-tabla-procesos0 {
	/* width: 40%; */
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-procesos1 {
	/* width: 40%; */
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-procesos2 {
	/* width: 40%; */
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-etapas1 {
	width: 5%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-etapas2 {
	width: 30%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-etapas3 {
	width: 18%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-etapas4 {
	width: 7%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}

.titulo-tabla-etapas1 {
	width: 400px;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}

.btn-form-procesos {
	background-color: #224459;
	color: #fff;
	margin-left: 10%;
	width: 15%;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 40px;
	border-radius: 10px;
	font-size: 23px;
}
.btn-crear-proceso {
	background-color: #6fd65f;
	padding-left: 20px;
	padding-right: 20px;
	color: #fff;
	margin-left: 50px;
	width: auto;
	margin-top: 0px;
	margin-bottom: 10px;
	/* height: 40px; */
	border-radius: 10px;
	font-size: 20px;
}

.titulo1 {
	font-size: 25px;
	margin-left: 20px;
}

.titulo2 {
	font-size: 20px;
	color: rgb(228, 38, 101);
	margin: 30px;
}
.titulo3 {
	margin-top: 2px;
	margin-bottom: 2px;
	margin-left: 20px;
	font-size: 20px;
}
.titulo4 {
	margin-left: 20px;
	font-size: 12px;
}

.titulo5 {
	padding-left: 10px;
	color: white;
	font-size: 15px;
	margin-bottom: 0px;
}

.titulo6 {
	margin-left: 20px;
	font-size: 15px;
}
.titulo7 {
	margin-left: 20px;
	font-size: 25px;
}
.titulo8 {
	margin-left: 20px;
	font-size: 25px;
	color: white;
}
.titulo9 {
	font-size: 30px;
	color: rgb(70, 102, 244);
	margin: 5px;
	text-align: center;
}
.titulo10 {
	font-size: 20px;
	color: white;
	margin-left: 40px;

	/* text-	align: center; */
}
.titulo11 {
	font-size: 30px;
	color: #567d8c;
	margin-left: 40px;
	text-align: center;
}

.titulo12 {
	margin-left: 20px;
	font-size: 15px;
	color: #2ca816;
}
.titulo13 {
	margin-left: 20px;
	font-size: 15px;
	color: red;
}

.titulo14 {
	margin: 0px;
	padding: 0px;
	padding-left: 10px;
	font-size: 17px;
}

.col-tabla-tarea1 {
	width: 4%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-tarea2 {
	width: 15%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-tarea3 {
	width: 10%;
	height: 30px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}
.col-tabla-tarea4 {
	width: 5%;
	height: 40px;
	font-size: 13px;
	border-style: groove;
	border-color: #79b4d9;
	padding-left: 10px;
}

.btn-filter-procesos {
	width: 10%;
	font-size: 20px;
	font-weight: 700;
	padding-left: 10px;
	padding-right: 10px;
	margin: 3px;
	margin-left: 20px;
	background-color: #6fd65f;
	/* color: white; */
	color: #fff;
	border-radius: 5px;
}
.btnCleanFilterProcess {
	width: 10%;
	font-size: 20px;
	font-weight: 700;
	padding-left: 10px;
	padding-right: 10px;
	margin: 3px;
	margin-left: 20px;
	background-color: withe;
	/* color: white; */
	color: black;
	border-radius: 5px;
}

.filter-procesos {
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 10px;
	width: 95%;
	background-color: #dcebf5;
	border-radius: 10px;
}

.view-documents {
	width: 100%;
	height: 100%;
}
.view-documents-container {
	width: 100%;
	height: 100vh;
	overflow-y: auto;
}

.tableProcess {
	margin: 10px;
}
.contentCompliance01 {
	background-color: rgb(227, 239, 253);
	margin-left: 20px;
	margin-right: 20px;
	height: 40px;
	border-radius: 10px;
	border-style: solid;
	align-content: cnter;
}

.contActions01 {
	display: flex;
}

.formStop {
	background-color: rgb(230, 84, 133);
	padding: 10px;
	/* margin: 20px; */
	border-radius: 10px;
}

.hrWhite {
	margin-top: 0px;
	margin-bottom: 0px;
	/* margin-left: 20px;
	margin-right: 20px; */
	border-color: white;
	color: red;
}

@media only screen and (max-width: 800px) {
	.titulo1 {
		margin-left: 5px;
		font-size: 12px;
	}

	.titulo2 {
		font-size: 12px;
		color: rgb(228, 38, 101);
		margin: 10px;
	}
	.titulo3 {
		margin-left: 5px;
		font-size: 12px;
	}
	.titulo4 {
		margin-left: 5px;
		font-size: 9px;
	}

	.titulo5 {
		padding-left: 3px;
		font-size: 10px;
	}

	.titulo6 {
		margin-left: 5px;
		font-size: 10px;
	}
	.titulo7 {
		margin-left: 5px;
		font-size: 15px;
	}
	.titulo8 {
		margin-left: 5px;
		font-size: 15px;
	}
	.titulo9 {
		font-size: 17px;
		margin: 3px;
	}
	.titulo10 {
		font-size: 12px;
		margin-left: 10px;
	}
	.titulo11 {
		font-size: 17px;
		margin-left: 20px;
	}

	.titulo12 {
		margin-left: 5px;
		font-size: 10px;
	}
	.titulo13 {
		margin-left: 5px;
		font-size: 10px;
	}

	.titulo14 {
		padding-left: 3px;
		font-size: 11px;
	}

	.tabla-procesos {
		margin-top: 5px;
		margin-left: 1%;
		margin-right: 1%;
		width: 98%;
	}
	.tabla-procesos td {
		font-size: 9px;
	}

	.titulo-tabla-procesos {
		padding-left: 3px;
		font-size: 12px;
		/* width: 500px; */
		border-style: groove;
		border-color: #79b4d9;
	}

	.input0 {
		margin: 0px;
		margin-left: 1%;
		padding: 0px;
		width: 98%;
		padding-left: 5px;
		font-size: 12px;
	}

	.btn-crear-proceso {
		padding-left: 10px;
		padding-right: 10px;
		margin-left: 50px;
		width: auto;
		margin-top: 0px;
		margin-bottom: 10px;
		border-radius: 5px;
		font-size: 12px;
	}
}
